import React, {useEffect} from "react";
import {signOut} from "firebase/auth";

import {auth} from "../auth/auth";
import { profileStore } from "../profile/profileStore";
import { userStore } from "../auth/userStore";
import { observer } from "mobx-react-lite";

const SettingsPage = observer(() => {
  useEffect(() => {
    profileStore.getProfile();
  }, [])


  console.log('profileStore', profileStore);
  if (!profileStore.profile) return <div>Loading...</div>;

  // @ts-ignore
  const name = profileStore.profile.name || userStore.user.displayName;
  console.log('userStore.user', userStore.user);

  return (
    <div>
      <div>Settings Page</div>

      <h1>Hi, {name}</h1>
      <h2>Your activation code is here: {profileStore.profile.activationCode}</h2>
      <button onClick={() => signOut(auth)}>Sign-out</button>

      <h3>Profile form:</h3>
      <form action="">
        <input type="text" name="name" defaultValue={name} />
      </form>
    </div>
  )
});

export default SettingsPage;
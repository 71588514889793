import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';

type ProtectedRouteProps = {
  user: any,
  redirectPath?: string,
  children?: React.ReactElement
}
const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  user,
  redirectPath = '/auth',
  children,
}) => {
  if (!user) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};

export default ProtectedRoute;
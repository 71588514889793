import { makeObservable, observable, action } from "mobx"
import ProfileService from './ProfileService';

type UserEntity = {
  activationCode: string,
  name: string,
  userId: string,
} | null;

export class User {
  profile: {
    activationCode: string,
    name: string,
    userId: string,
  } | null = null;

  constructor() {
    makeObservable(this, {
      profile: observable,
    })
  }

  getProfile() {
    ProfileService.getProfile().then(action("getProfileSuccess", (data) => {
      this.profile = data as UserEntity;
    }))
  }
}

export const profileStore = new User();
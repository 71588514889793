import axios, { Method } from 'axios';

import CONFIG from '../config';
import { userStore } from '../auth/userStore';


const instance = axios.create({ baseURL: CONFIG.API_URL });

const request = (method: Method, url: string, data: any) => new Promise((resolve, reject) => {
  (() => {
    const headers = {
      // @ts-ignore
      ...(userStore.user ? { 'AuthToken': userStore.user.accessToken } : {})
    };
    if (method === 'get') {
      return instance.request({
        url, method, params: data, headers
      });
    }
    return instance.request({
      url, method, data, headers,
    });
  })()
    .then((res) => {
      resolve(res.data);
    })
    .catch((err) => {
      reject(err.response);
    });
});

export default {
  get: (endpoint: string, data?: any) => request('get', endpoint, data),
  patch: (endpoint: string, data?: any) => request('patch', endpoint, data),
  post: (endpoint: string, data?: any) => request('post', endpoint, data),
  put: (endpoint: string, data?: any) => request('put', endpoint, data),
  del: (endpoint: string, data?: any) => request('delete', endpoint, data),
};
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { observer } from "mobx-react-lite"

import './auth/auth'; // To init auth module
import SettingsPage from "./settings/SettingsPage";
import LoginPage from "./auth/LoginPage";
import ProtectedRoute from "./auth/ProtectedRoute";
import { User } from "./auth/userStore";

import './styles/normalize.css';
import './App.css';

type AppProps = {
  userStore: User;
}

const App: React.FC<AppProps> = observer(({ userStore }) => {
  if (!userStore.isAuthInit) {
    return <div>App initialization...</div>;
  }

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="auth" element={<LoginPage />} />

          <Route element={<ProtectedRoute user={userStore.user} />}>
            <Route path="/" element={<SettingsPage />} />
            <Route path="settings" element={<SettingsPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
});

export default App;

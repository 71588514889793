import { makeObservable, observable, action } from "mobx"

export class User {
  user = null;
  isAuthInit = false;

  constructor() {
    makeObservable(this, {
      user: observable,
      isAuthInit: observable,
      setUser: action
    })
  }

  setUser(user: any) {
    this.user = user;
    this.isAuthInit = true;
  }
}

export const userStore = new User();
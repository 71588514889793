
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
import {initializeApp} from "firebase/app";
import {browserSessionPersistence, initializeAuth} from "firebase/auth";
import { userStore } from './userStore';

const firebaseConfig = {
    apiKey: "AIzaSyDN7B8F_w-ZcGERK44cFGRVUM3e0zEB3No",
    authDomain: "trust-assistant-fd3de.firebaseapp.com",
    projectId: "trust-assistant-fd3de",
    storageBucket: "trust-assistant-fd3de.appspot.com",
    messagingSenderId: "62308069746",
    appId: "1:62308069746:web:b17f1b89a75d964bd26deb",
    measurementId: "G-61QLJW01K5"
};

const app = initializeApp(firebaseConfig);
const auth = initializeAuth(app, {
    persistence: browserSessionPersistence,
})
auth.onAuthStateChanged((user) => {
    userStore.setUser(user);
})

export {
    app,
    auth,
};

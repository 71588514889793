import React, {useEffect, useRef} from "react";
import { useNavigate } from "react-router";
import { signOut, GoogleAuthProvider, EmailAuthProvider, FacebookAuthProvider } from "firebase/auth";
import * as firebaseui from 'firebaseui';

import { auth } from './auth'
import styles from './LoginPage.module.css';
import 'firebaseui/dist/firebaseui.css';

const ui = new firebaseui.auth.AuthUI(auth);
const LoginPage = () => {
    const navigate = useNavigate();
    const authWrapperEl = useRef(null);
    useEffect(() => {
        if (authWrapperEl.current) {

            ui.start(`#firebaseui-auth-container`, {
                signInOptions: [GoogleAuthProvider.PROVIDER_ID, EmailAuthProvider.PROVIDER_ID, FacebookAuthProvider.PROVIDER_ID],
                signInFlow: 'popup',
                'callbacks': {
                    // Called when the user has been successfully signed in.
                    'signInSuccessWithAuthResult': function(authResult, redirectUrl) {
                        console.log('authResult', authResult);
                        console.log('authResult.user', authResult.user);
                        // Do not redirect.
                        navigate('/settings');
                        return false;
                    }
                },
                tosUrl: '/terms',
                privacyPolicyUrl: function() {
                    window.location.assign('/privacy-policy');
                }
            });
        }
    }, []);

    return (
        <div className={styles.wrapper}>
            <h1>Welcome to trust assistant !</h1>
            <div id="firebaseui-auth-container" ref={authWrapperEl}></div>
        </div>
    )
}

export default LoginPage;